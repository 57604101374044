html,
body {
    font-family: 'Roboto', Arial, sans-serif !important;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    background: #f2f4f5 !important;
    color: #5c5c5c !important;
}

.fs-50 {
    font-size: 50px !important;
    font-weight: 300 !important;
}

.fs-37 {
    font-size: 37px !important;
    font-weight: 300 !important;
}

.ColorName {
    font-size: 28px !important;
    font-weight: 300 !important;
}

.YellowBox {
    padding: 10px;
    color: #000;
    background: #fabd4c;
    text-align: center;
    font-size: 1.5em;
}
.footerClass{
    box-sizing: border-box;
    padding: 15px 0;
    background: #414349;
    color: #adadae;
    font-size: 14px;
}